export const ROOT_PATH = '/';
export const LOGIN_PATH = '/login';
export const ALL_LANGUAGES_PATH = '/languages';
export const ALL_WORDS_PATH = '/words';
export const PHRASE_CONSTRUCTOR_PATH = '/phrase-constructor';
export const ADD_WORDS_PATH = '/add';
// export const ALL_TRAININGS_PATH = '/trainings';
// export const TRAINING_TRANSLATION_PATH = '/translation';
// export const TRAINING_WRITING_PATH = '/writing';
// export const TRAINING_BLITZ_PATH = '/blitz';
// export const TRAINING_RESULT_PATH = '/result';
